// @flow
import React from 'react';

import CheckboxLine from 'components/CheckboxLine';
import { formatDate } from 'helpers';
import { disciplinesByName } from 'constants/disciplines';
import type { SegmentType } from 'context/SelectedSegments/type';

import { NumberOfNominations } from '../NumberOfNominations';
import { useDisciplineModalHooks } from '../EntryDisciplineModal/hooks';

type SegmentsByDisciplineCheckListProps = {|
  toggleSegment: (SegmentType, number) => void,
  isSelected: (SegmentType, number) => boolean,
  disciplineTypeUID: number,
  segments: Object[],
  hideDisciplineTitle?: boolean,
|};

const SegmentsByDisciplineCheckList = ({
  segments,
  toggleSegment,
  isSelected,
  disciplineTypeUID,
  hideDisciplineTitle = false,
}: SegmentsByDisciplineCheckListProps) => {
  const { activeDiscipline } = useDisciplineModalHooks();
  const methods = useDisciplineModalHooks();
  const { setCountActiveSegment } = methods;
  const activeSegments = activeDiscipline ? activeDiscipline.segments : [];

  return (
    <div key={`discipline-segments-${disciplineTypeUID.toString()}`}>
      {!hideDisciplineTitle ? (
        <div
          key={`discipline-segments-label-${disciplineTypeUID.toString()}`}
          className="discipline-name-area"
        >
          {disciplinesByName[disciplineTypeUID]}
        </div>
      ) : null}
      {segments.map((option: SegmentType) => {
        const currentSelected = isSelected(option, disciplineTypeUID);
        let newSegmentCount = 1;
        if (activeSegments) {
          const currentActiveSegment = activeSegments.find(
            ({ value }) => value === option.value,
          );
          if (currentActiveSegment) {
            newSegmentCount = isNaN(currentActiveSegment.newSegmentCount)
              ? 1
              : currentActiveSegment.newSegmentCount;
          }
        }

        if (!currentSelected) newSegmentCount = 1;

        return (
          <div key={option.value}>
            <CheckboxLine
              key={option.value}
              isChecked={isSelected(option, disciplineTypeUID)}
              option={option}
              onChange={() => toggleSegment(option, disciplineTypeUID)}
            >
              {(segment: SegmentType) => {
                return (
                  <span className="segment-label">
                    <span className="segment-name">{segment.label}</span>
                    <span className="label">
                      {`${formatDate(segment.StartDate)} - ${formatDate(
                        segment.EndDate,
                      )}`}
                    </span>
                  </span>
                );
              }}
            </CheckboxLine>
            {currentSelected && activeDiscipline && activeDiscipline.value && (
              <NumberOfNominations
                activeDiscipline={activeDiscipline}
                setCountActiveSegment={setCountActiveSegment}
                segmentValue={option.value}
                newSegmentCount={newSegmentCount}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SegmentsByDisciplineCheckList;

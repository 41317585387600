// @flow
import React, { useContext, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import EventDivisionPartner from '../Event/EventDivisionPartner';

import { formatDate, formatDateTimeToCST } from 'helpers';

import { RET } from 'constants/theme/options';
import { EVENT_DIVISIONS } from 'constants/event';

import {
  EVENT_DIVISION_CSS_CLASSES,
  EVENT_DIVISION_NAMES,
} from 'constants/event';
import { NOMINATE } from 'constants/routes';
// $FlowFixMe
import eventIconRET from 'assets/img/icons/event-ret.svg';
// $FlowFixMe
import eventIconWCRA from 'assets/img/icons/event-wcra.svg';
// $FlowFixMe
import ticketIconRET from 'assets/img/icons/ticket-ret.svg';
// $FlowFixMe
import ticketIconWCRA from 'assets/img/icons/ticket-wcra.svg';

import type { EventType } from 'models/Event';
import { AthletesContext } from 'context/SelectedAthletes';
import type { ContextValue as AthletesContextValue } from 'context/SelectedAthletes';
import { setEventRank, selectEventYouthNomination } from 'actions/event';

const { THEME } = process.env;
const optionNominations = [
  { value: '1', label: 'YES' },
  { value: '0', label: 'NO' },
];

type EntryEventDescriptionPropsType = {|
  event: EventType,
  locationPathName?: string,
|};

const EntryEventDescription = (
  props: EntryEventDescriptionPropsType,
): React$Node => {
  const { event, locationPathName } = props;
  const {
    dateEnd,
    dateRegistrationEnd,
    dateStart,
    isAlliancePartner,
    EventName,
    location,
    EventRank,
    rankLabel,
    Associations,
    selectedYouthNomination,
  } = event;

  const dispatch = useDispatch();
  const showYouthNominationRadioButton = useMemo(
    () => locationPathName === NOMINATE,
    [locationPathName],
  );

  const [isYouthNomination, setIsYouthNomination] = useState(
    showYouthNominationRadioButton &&
      EVENT_DIVISIONS.DIVISION_YOUTH_2 === EventRank
      ? '1'
      : selectedYouthNomination,
  );
  const dateStartFormatted = formatDate(dateStart, 10);
  const dateEndFormatted = formatDate(dateEnd);
  const dateRange =
    !!dateStartFormatted && !!dateEndFormatted
      ? `${dateStartFormatted} - ${dateEndFormatted}`
      : dateStartFormatted;

  const { isYouthAthlete } = useContext<AthletesContextValue>(AthletesContext);

  const onChangeIsYouthNomination = (evt: Object) => {
    const currentIsYouthNomination = evt.target.value;
    const rank =
      currentIsYouthNomination === '1'
        ? EVENT_DIVISIONS.DIVISION_YOUTH_2
        : event.rank;
    setIsYouthNomination(currentIsYouthNomination);
    dispatch(setEventRank(rank));
    dispatch(selectEventYouthNomination(currentIsYouthNomination));
  };

  return (
    <>
      {Associations && Associations.length > 0 && (
        <>
          {Associations.map((association, i) => {
            return (
              <span
                className="association-short-name"
                key={association.AssociationUID}
              >
                {association ? association.ShortName : ''}
                {i !== Associations.length - 1 ? (
                  <span className="and-separator">&</span>
                ) : (
                  <span className="and-separator" />
                )}
              </span>
            );
          })}
          <div className="associations-finish-tag" />
        </>
      )}
      <div className="section-preferences-subtitle">{EventName}</div>
      <div className="event-location">{location}</div>
      <div className="event-dates">
        <img
          alt="event dates"
          src={THEME === RET ? eventIconRET : eventIconWCRA}
        />
        <strong>Event Dates: </strong>
        {dateRange}
      </div>
      {dateRegistrationEnd ? (
        <div className="event-entry-deadline">
          <img
            alt="entry deadline"
            src={THEME === RET ? ticketIconRET : ticketIconWCRA}
          />
          <strong>Entry Deadline: </strong>
          {formatDateTimeToCST(dateRegistrationEnd)}
        </div>
      ) : (
        ''
      )}
      <EventDivisionPartner
        rank={
          EventRank !== null &&
          EventRank !== undefined &&
          (EventRank + '').length > 2
            ? EVENT_DIVISION_CSS_CLASSES[EventRank]
            : EventRank
        }
        rankLabel={
          rankLabel === null ? EVENT_DIVISION_NAMES[EventRank] : rankLabel
        }
        isAlliancePartner={isAlliancePartner}
      />
      {isYouthAthlete && showYouthNominationRadioButton && !event.id ? (
        <div className="form-checkbox-wrapper">
          <FormControl>
            <FormLabel className="radio-group-container">
              <span className="radio-group-title">
                Are you nominating a youth event or class?
              </span>
            </FormLabel>
            <RadioGroup
              aria-label="chkbox-youth-nomination"
              name="chkbox-youth-nomination"
              value={isYouthNomination}
              className="radio-group-label"
              onChange={onChangeIsYouthNomination}
            >
              {optionNominations.map((option) => {
                return (
                  <FormControlLabel
                    className="radio-group-radio-label marbot-15"
                    key={option.label}
                    value={option.value}
                    control={
                      <Radio
                        className={
                          option.value === isYouthNomination
                            ? 'radio-group-radio-checked'
                            : 'radio-group-radio'
                        }
                        id={option.label}
                      />
                    }
                    label={option.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      ) : null}
    </>
  );
};

export default EntryEventDescription;

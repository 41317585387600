// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import NominationContent from 'components/NominationContent';
import SegmentsByDisciplineCheckList from './SegmentsByDisciplineCheckList';
import type { SegmentType } from 'context/SelectedSegments/type';
import { getDisciplineSegments } from 'selectors/disciplineSegments';
import { getSegments } from 'selectors/segment';
import { getEvent } from 'selectors/event';
import {
  getFilteredClassSegments,
  getFilteredSegments,
} from '../../models/DisciplineSegment';
import SegmentsByClassCheckList from './SegmentsByClassCheckList';
import { saveAvailableSegments } from '../../reducers/disciplineClass';
import { useDisciplineModalHooks } from '../EntryDisciplineModal/hooks';

type Props = {|
  toggleSegment: (SegmentType, number) => any,
  isSelected: (SegmentType, number) => boolean,
  isYouthAthlete: ?boolean,
  hideDisciplineTitle?: boolean,
  isFemaleAthlete: ?boolean,
  onClassSegmentSelected: Function,
  selectedClassSegments: Array<any>,
|};

const DisciplineSegmentsCheckList = (props: Props) => {
  const disciplineSegments = useSelector(getDisciplineSegments);
  const segments = useSelector(getSegments);
  const event = useSelector(getEvent);
  const dispatch = useDispatch();
  const selectedClasses = useSelector(
    (state) => state.disciplineClass.selected,
  );

  const {
    toggleSegment,
    isSelected,
    isYouthAthlete,
    isFemaleAthlete,
    hideDisciplineTitle,
    onClassSegmentSelected,
    selectedClassSegments,
  } = props;

  const { activeDiscipline } = useDisciplineModalHooks();

  let filteredSegments = [];
  if (activeDiscipline && activeDiscipline.Division) {
    filteredSegments = getFilteredClassSegments(
      segments,
      !!isYouthAthlete,
      !!isFemaleAthlete,
      activeDiscipline.Division,
    );
  } else {
    filteredSegments = getFilteredSegments(
      segments,
      !!isYouthAthlete,
      !!isFemaleAthlete,
      event,
    );
  }

  useEffect(() => {
    dispatch(saveAvailableSegments(filteredSegments));
  }, []);

  return (
    <NominationContent
      removeOriginalClassName={true}
      className="segments-selection"
    >
      {Object.keys(disciplineSegments).map((disciplineKey) => {
        if (!selectedClasses || selectedClasses.length === 0) {
          return (
            <SegmentsByDisciplineCheckList
              key={`discipline-segments-${disciplineKey}`}
              disciplineTypeUID={Number(disciplineKey)}
              toggleSegment={toggleSegment}
              isSelected={isSelected}
              segments={filteredSegments.filter(
                (segment) =>
                  disciplineSegments[disciplineKey].includes(segment.value) &&
                  (moment(event.dateStart).isSameOrAfter(segment.StartDate) &&
                    moment(event.dateEnd).isSameOrBefore(segment.EndDate)),
              )}
              hideDisciplineTitle={hideDisciplineTitle}
            />
          );
        } else {
          return (
            <SegmentsByClassCheckList
              segments={segments.filter(
                (segment) =>
                  disciplineSegments[disciplineKey].includes(segment.value) &&
                  (moment(event.dateStart).isSameOrAfter(segment.StartDate) &&
                    moment(event.dateEnd).isSameOrBefore(segment.EndDate)),
              )}
              disciplineTypeUID={Number(disciplineKey)}
              onClassSegmentSelected={onClassSegmentSelected}
              selectedClassSegments={selectedClassSegments}
              key={`discipline-segments-${disciplineKey}`}
              isYouthAthlete={isYouthAthlete}
              isFemaleAthlete={isFemaleAthlete}
            />
          );
        }
      })}
    </NominationContent>
  );
};

export default DisciplineSegmentsCheckList;
